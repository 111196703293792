import * as Sentry from '@sentry/react';
import { redirect } from 'react-router-dom';
import client, { formError } from './client';
import { components } from './openapi-schema';

export type GuestSaleRequest = components['schemas']['GuestSaleRequest'];

export async function guestSale(request: GuestSaleRequest) {
  const { error } = await client.POST('/usaepay/guestsale', {
    body: request,
  });

  if (error?.status === 404) {
    return formError(
      'Invoice not found. Please check your information and try again.',
    );
  }

  if (error) {
    console.error('Error making guest payment', error);
    Sentry.captureMessage('Error making guest payment', {
      level: 'error',
      extra: { error },
    });
    return formError('Something went wrong. Please try again.');
  }

  return redirect('/guest-payment/success');
}
