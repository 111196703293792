import { useState } from 'react';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';
import { InvoiceDetails } from '@/api/invoices';
import FormInput from '@/components/FormInput';
import PaymentSummaryTable from '@/components/PaymentSummaryTable';
import TwoColumnCard from '@/components/TwoColumnCard';
import { parseNumberInput } from '@/utils/numbers';

const TIP_PERCENTS = [0.15, 0.2];

export interface ConfirmAmountCardProps {
  readonly invoice: InvoiceDetails;
}

export default function ConfirmAmountCard({ invoice }: ConfirmAmountCardProps) {
  const [additionalServicesString, setAdditionalServicesString] = useState('');
  const [tipString, setTipString] = useState('');

  const invoiceBalance = invoice.balanceDue - invoice.salesTaxAmount;
  const additionalServices = parseNumberInput(additionalServicesString);
  const tip = parseNumberInput(tipString);

  const subtotalWithAdditionalServices = invoiceBalance + additionalServices;

  const salesTax =
    invoice.salesTaxAmount + additionalServices * invoice.salesTaxRate;

  function onAdditionalServicesChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    setAdditionalServicesString(event.target.value);
  }

  function onTipChange(event: React.ChangeEvent<HTMLInputElement>) {
    setTipString(event.target.value);
  }

  return (
    <TwoColumnCard title="Confirm Amount">
      <input type="hidden" name="franchiseId" value={invoice.franchiseId} />
      <input
        type="hidden"
        name="workOrderNumber"
        value={invoice.workOrderNumber}
      />

      <p>
        Did you change the work done at time of service? You can add charges for
        additional services or enter a negative amount to reduce your invoice
        for any removed services. Please add a note if a negative amount is
        entered.
      </p>
      <Row>
        <Col md={6}>
          <FormInput
            name="additionalServices"
            label="Additional Services"
            prefix="$"
            type="number"
            placeholder="0.00"
            step={0.01}
            min={0}
            value={additionalServicesString}
            onChange={onAdditionalServicesChange}
          />
        </Col>
        <Col md={6}>
          <FormInput
            name="additionalServicesNote"
            label="Note"
            required={additionalServices < 0}
          />
        </Col>
        <Col md={6}>
          <FormInput
            name="tip"
            label="Tip"
            prefix="$"
            suffix={formatTipPercent(subtotalWithAdditionalServices, tip)}
            type="number"
            placeholder="0.00"
            step={0.01}
            min={0}
            value={tipString}
            onChange={onTipChange}
          />

          <ButtonGroup className="d-block">
            {TIP_PERCENTS.map((percent) => (
              <ToggleTipButton
                key={percent}
                percent={percent}
                amount={subtotalWithAdditionalServices}
                tip={tip}
                setTipString={setTipString}
              />
            ))}
          </ButtonGroup>
        </Col>
      </Row>

      <PaymentSummaryTable
        className="mt-4"
        invoiceTotal={invoiceBalance}
        additionalServices={additionalServices}
        tax={salesTax}
        tip={tip}
      />
    </TwoColumnCard>
  );
}

interface ToggleTipButtonProps {
  readonly percent: number;
  readonly amount: number;
  readonly tip: number;
  readonly setTipString: (value: string) => void;
}

function ToggleTipButton({
  percent,
  amount,
  tip,
  setTipString,
}: ToggleTipButtonProps) {
  const isActive = amount > 0 && Math.abs(tip - amount * percent) < 0.01;

  function onClick() {
    if (isActive) {
      setTipString('');
    } else {
      setTipString((amount * percent).toFixed(2));
    }
  }

  return (
    <Button
      key={percent}
      color="outline-secondary"
      active={isActive}
      onClick={onClick}
    >
      {(percent * 100).toFixed(0)}%
    </Button>
  );
}

function formatTipPercent(amount: number, tip: number) {
  if (amount > 0 && tip > 0) {
    return ((tip / amount) * 100).toFixed(0) + '%';
  } else {
    return '';
  }
}
