import './sentry.ts';
import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { isLoggedIn } from './api/auth.ts';
import confirmUserAction from './routes/auth/confirm-user.action.ts';
import confirmUserLoader from './routes/auth/confirm-user.loader.ts';
import ConfirmUserPage from './routes/auth/confirm-user.tsx';
import forgotPasswordAction from './routes/auth/forgot-password.action.ts';
import ForgotPasswordPage from './routes/auth/forgot-password.tsx';
import loginAction from './routes/auth/login.action.ts';
import LoginPage from './routes/auth/login.tsx';
import RegisterSuccessPage from './routes/auth/register-success.tsx';
import registerAction from './routes/auth/register.action.ts';
import RegisterPage from './routes/auth/register.tsx';
import resetPasswordAction from './routes/auth/reset-password.action.ts';
import ResetPasswordPage from './routes/auth/reset-password.tsx';
import ErrorPage from './routes/error.tsx';
import IndexPage from './routes/guest/index.tsx';
import guestLayoutLoader from './routes/guest/layout.loader.ts';
import GuestPaymentLayout from './routes/guest/payment/layout.tsx';
import payInvoiceAction from './routes/guest/payment/pay-invoice.action.ts';
import payInvoiceLoader from './routes/guest/payment/pay-invoice.loader.ts';
import PayInvoicePage from './routes/guest/payment/pay-invoice.tsx';
import SuccessfulGuestPaymentPage from './routes/guest/payment/success.tsx';
import BaseLayout from './routes/layout.tsx';
import addLocationAction from './routes/logged-in/add-location.action.ts';
import AddLocationPage from './routes/logged-in/add-location.tsx';
import invoicesAction from './routes/logged-in/dashboard/invoices.action.ts';
import InvoicesPage from './routes/logged-in/dashboard/invoices.tsx';
import dashboardLayoutLoader from './routes/logged-in/dashboard/layout.loader.ts';
import DashboardLayout from './routes/logged-in/dashboard/layout.tsx';
import loggedInLayoutLoader from './routes/logged-in/layout.loader.ts';
import LoggedInLayout from './routes/logged-in/layout.tsx';
import { makePendingFlashMessageCurrent } from './utils/flashMessage.ts';

function redirectIfAuthedLoader() {
  if (isLoggedIn()) {
    return redirect('/');
  }

  return {};
}

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <BaseLayout />,
    errorElement: <ErrorPage />,
    children: [
      // Non-logged in routes
      {
        loader: guestLayoutLoader,
        children: [
          {
            path: '/',
            element: <IndexPage />,
          },
          // Payment routes
          {
            element: <GuestPaymentLayout />,
            children: [
              {
                path: '/guest-payment',
                loader: payInvoiceLoader,
                action: payInvoiceAction,
                element: <PayInvoicePage />,
              },
              {
                path: '/guest-payment/success',
                element: <SuccessfulGuestPaymentPage />,
              },
            ],
          },
        ],
      },
      // Auth routes
      {
        loader: redirectIfAuthedLoader,
        children: [
          {
            path: '/login',
            action: loginAction,
            element: <LoginPage />,
          },
          {
            path: '/register',
            action: registerAction,
            element: <RegisterPage />,
          },
          {
            path: '/register/success',
            element: <RegisterSuccessPage />,
          },
          {
            path: '/forgot-password',
            action: forgotPasswordAction,
            element: <ForgotPasswordPage />,
          },
          {
            path: '/resetpassword',
            action: resetPasswordAction,
            element: <ResetPasswordPage />,
          },
          {
            path: '/confirm',
            loader: confirmUserLoader,
            action: confirmUserAction,
            shouldRevalidate: () => false,
            element: <ConfirmUserPage />,
          },
        ],
      },
      // Logged in routes
      {
        id: 'logged-in',
        loader: loggedInLayoutLoader,
        element: <LoggedInLayout />,
        children: [
          {
            path: '/locations/add',
            action: addLocationAction,
            element: <AddLocationPage />,
          },
          // Dashboard routes
          {
            id: 'dashboard',
            loader: dashboardLayoutLoader,
            element: <DashboardLayout />,
            children: [
              {
                path: '/invoices',
                element: <InvoicesPage />,
              },
              {
                path: '/locations/:locationId',
                action: invoicesAction,
                element: <InvoicesPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

router.subscribe((state) => {
  if (state.navigation.state === 'idle') {
    makePendingFlashMessageCurrent();
  }
});

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
);
