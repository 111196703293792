import { useMap, useSet } from '@uidotdev/usehooks';
import { Fragment } from 'react';
import { FaCreditCard, FaGear, FaMinus, FaPlus } from 'react-icons/fa6';
import { Form, useParams } from 'react-router-dom';
import {
  Badge,
  Card,
  DropdownMenu,
  DropdownToggle,
  Input,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import styled from 'styled-components';
import { getInvoiceStatus, Invoice, InvoiceStatus } from '@/api/invoices';
import { formatLocationAddressShort } from '@/api/locations';
import FlashMessage from '@/components/FlashMessage';
import HeaderSelectionCheckbox from '@/components/HeaderSelectionCheckbox';
import IconButton from '@/components/IconButton';
import InputWithPrefixSuffix from '@/components/InputWithPrefixSuffix';
import LinkButton from '@/components/LinkButton';
import PageHeader from '@/components/PageHeader';
import PaymentSummaryTable from '@/components/PaymentSummaryTable';
import { parseNumberInput } from '@/utils/numbers';
import { useLoggedInLayoutLoaderData } from '../layout.loader';
import { useDashboardLayoutLoaderData } from './layout.loader';

const TableWrapper = styled(Card)`
  overflow: hidden;
`;

const StyledTable = styled(Table)`
  margin: 0;

  th:first-child,
  td:first-child {
    padding-left: 1rem;
  }

  th:last-child,
  td:last-child {
    padding-right: 1rem;
  }

  thead th {
    background: var(--bs-gray-100);
  }

  tbody tr:last-child td {
    border-bottom: 0;
  }
`;

const TipInput = styled(InputWithPrefixSuffix)`
  display: inline-flex;
  width: 150px;

  &:has(input:disabled) {
    opacity: 0.5;
  }
`;

const AmountText = styled.span`
  display: inline-block;
  min-width: 150px;
`;

const AmountInput = styled(InputWithPrefixSuffix)`
  display: inline-flex;
  width: 150px;
`;

const StyledPaymentSummaryTable = styled(PaymentSummaryTable)`
  max-width: 400px;
  margin-left: auto;

  th,
  td {
    background: transparent;
  }
`;

function formatDate(dateString: string) {
  const date = new Date(dateString);
  return date.toLocaleDateString();
}

function useCurrentLocation() {
  const { locations } = useLoggedInLayoutLoaderData();
  const { invoices: allInvoices } = useDashboardLayoutLoaderData();
  const { locationId: locationIdString } = useParams();

  const locationId =
    locationIdString != null ? parseInt(locationIdString) : null;

  if (locationId == null) {
    return { location: null, invoices: allInvoices };
  } else {
    const location = locations.find((l) => l.locationId === locationId);

    if (location == null) {
      throw new Error(`Location not found: ${locationId}`);
    }

    const invoices = allInvoices.filter(
      (invoice) => invoice.locationId === locationId,
    );

    return { location, invoices };
  }
}

export default function InvoicesPage() {
  const { location, invoices } = useCurrentLocation();

  const selectedInvoiceIds = useSet(
    invoices.map((invoice) => invoice.documentId),
  );

  const additionalServiceAmounts = useMap<number>() as Map<number, string>;
  const invoiceTipAmounts = useMap<number>() as Map<number, string>;

  const selectedInvoices = invoices.filter((invoice) =>
    selectedInvoiceIds.has(invoice.documentId),
  );

  const invoiceAmounts = selectedInvoices.map((invoice) =>
    getInvoiceAmounts(invoice, additionalServiceAmounts, invoiceTipAmounts),
  );

  const invoiceTotal = invoiceAmounts.reduce(
    (sum, invoice) => sum + invoice.balanceDue,
    0,
  );

  const additionalServices = invoiceAmounts.reduce(
    (sum, invoice) => sum + invoice.additionalServices,
    0,
  );

  const tips = invoiceAmounts.reduce((sum, invoice) => sum + invoice.tip, 0);

  const salesTax = invoiceAmounts.reduce(
    (sum, invoice) => sum + invoice.salesTax,
    0,
  );

  function toggleInvoiceSelection(invoiceId: number) {
    if (selectedInvoiceIds.has(invoiceId)) {
      selectedInvoiceIds.delete(invoiceId);
      additionalServiceAmounts.delete(invoiceId);
      invoiceTipAmounts.delete(invoiceId);
    } else {
      selectedInvoiceIds.add(invoiceId);
    }
  }

  function selectAllInvoices() {
    invoices.forEach((invoice) => selectedInvoiceIds.add(invoice.documentId));
  }

  return (
    <>
      <FlashMessage className="mb-3" />
      <PageHeader
        title={location?.description ?? 'All Unpaid Invoices'}
        subtitle={
          location != null ? formatLocationAddressShort(location) : null
        }
      >
        <LinkButton to="/locations/add">
          <FaPlus /> Add Location
        </LinkButton>

        {location && (
          <Form method="POST">
            <UncontrolledDropdown>
              <DropdownToggle color="outline-secondary" className="icon-btn">
                <FaGear />
              </DropdownToggle>
              <DropdownMenu end>
                {/* Need to use a normal button here as DropdownItem doesn't seem to submit the form properly. */}
                <button
                  type="submit"
                  name="action"
                  value="delete_location"
                  className="dropdown-item"
                  role="menuitem"
                >
                  Delete location
                </button>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Form>
        )}
      </PageHeader>

      <p className="text-secondary">
        You can select specific invoices you&apos;d like to pay if you
        don&apos;t want to pay all outstanding invoices.
      </p>

      <p className="text-secondary">
        Did you change the work done at time of service? You can click the plus
        symbol for any invoice row to add charges for additional services or
        enter a negative amount to reduce your invoice for any removed services.
        Please add a note if a negative amount is entered.
      </p>

      <TableWrapper>
        <StyledTable>
          <thead>
            <tr>
              <th>
                <HeaderSelectionCheckbox
                  selected={selectedInvoiceIds.size}
                  total={invoices.length}
                  onSelectAll={selectAllInvoices}
                  onClearAll={() => selectedInvoiceIds.clear()}
                />
              </th>
              <th>Work Order #</th>
              <th>Date</th>
              <th>Address</th>
              <th className="text-end">Amount</th>
              <th className="text-end">Status</th>
              <th className="text-end">Add a tip</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <Fragment key={invoice.documentId}>
                <tr>
                  <td className="align-middle">
                    <Input
                      type="checkbox"
                      checked={selectedInvoiceIds.has(invoice.documentId)}
                      onChange={() =>
                        toggleInvoiceSelection(invoice.documentId)
                      }
                    />
                  </td>
                  <td className="align-middle">
                    <a
                      href={invoice.pdfLink}
                      target="_blank"
                      rel="noreferrer"
                      className="text-dark fw-bold"
                    >
                      {invoice.workOrderNumber}
                    </a>
                  </td>
                  <td className="tabular-nums align-middle">
                    {invoice.completedDate ? (
                      formatDate(invoice.completedDate)
                    ) : (
                      <span className="text-secondary">Upcoming</span>
                    )}
                  </td>
                  <td className="align-middle">
                    {formatLocationAddressShort(invoice)}
                  </td>
                  <td className="tabular-nums align-middle text-end">
                    <AmountText>$ {invoice.balanceDue.toFixed(2)}</AmountText>
                  </td>
                  <td className="align-middle text-end">
                    <InvoiceStatusBadge invoice={invoice} />
                  </td>
                  <td className="text-end">
                    <TipInput
                      prefix="$"
                      placeholder="0.00"
                      disabled={!selectedInvoiceIds.has(invoice.documentId)}
                      value={invoiceTipAmounts.get(invoice.documentId) ?? ''}
                      onChange={(event) =>
                        invoiceTipAmounts.set(
                          invoice.documentId,
                          event.target.value,
                        )
                      }
                    />
                  </td>
                  <td className="align-middle text-end">
                    {!additionalServiceAmounts.has(invoice.documentId) && (
                      <IconButton
                        color="outline-secondary"
                        onClick={() =>
                          additionalServiceAmounts.set(invoice.documentId, '')
                        }
                        disabled={!selectedInvoiceIds.has(invoice.documentId)}
                      >
                        <FaPlus />
                      </IconButton>
                    )}
                  </td>
                </tr>
                {additionalServiceAmounts.has(invoice.documentId) && (
                  <AdditionalServicesRow
                    amount={
                      additionalServiceAmounts.get(invoice.documentId) ?? ''
                    }
                    onAmountChange={(amount) =>
                      additionalServiceAmounts.set(invoice.documentId, amount)
                    }
                    onRemoveClick={() =>
                      additionalServiceAmounts.delete(invoice.documentId)
                    }
                  />
                )}
              </Fragment>
            ))}
          </tbody>
        </StyledTable>
      </TableWrapper>

      <StyledPaymentSummaryTable
        className="mt-4"
        invoiceTotal={invoiceTotal}
        additionalServices={additionalServices}
        tax={salesTax}
        tip={tips}
        multipleInvoices
      />

      <div className="d-flex justify-content-end mt-5">
        <LinkButton
          to="/pay"
          color="primary"
          size="lg"
          disabled={selectedInvoiceIds.size == 0}
        >
          <FaCreditCard /> Pay Selected Invoices
        </LinkButton>
      </div>
    </>
  );
}

interface InvoiceStatusBadgeProps {
  readonly invoice: Invoice;
}

function InvoiceStatusBadge({ invoice }: InvoiceStatusBadgeProps) {
  const status = getInvoiceStatus(invoice);

  if (status == InvoiceStatus.Pending) {
    return <Badge color="secondary">Pending</Badge>;
  } else if (status == InvoiceStatus.Due) {
    return <Badge color="warning">Due</Badge>;
  } else if (status == InvoiceStatus.Overdue) {
    return <Badge color="danger">Overdue</Badge>;
  }
}

interface AdditionalServicesRowProps {
  readonly amount: string;
  readonly onAmountChange: (amount: string) => void;
  readonly onRemoveClick: () => void;
}

function AdditionalServicesRow({
  amount,
  onAmountChange,
  onRemoveClick,
}: AdditionalServicesRowProps) {
  const isNoteRequired = parseNumberInput(amount) < 0;

  return (
    <tr>
      <td colSpan={3}></td>
      <td className="align-middle text-secondary fst-italic">
        Additional services
      </td>
      <td className="text-end">
        <AmountInput
          size={1}
          prefix="$"
          placeholder="0.00"
          value={amount}
          onChange={(event) => onAmountChange(event.target.value)}
        />
      </td>
      <td colSpan={2}>
        <InputWithPrefixSuffix
          size={1}
          type="text"
          placeholder={isNoteRequired ? 'Note (required)' : 'Note (optional)'}
          required={isNoteRequired}
        />
      </td>
      <td className="align-middle text-end">
        <IconButton color="outline-danger" onClick={onRemoveClick}>
          <FaMinus />
        </IconButton>
      </td>
    </tr>
  );
}

function getInvoiceAmounts(
  invoice: Invoice,
  additionalServiceAmounts: Map<number, string>,
  invoiceTipAmounts: Map<number, string>,
) {
  const balanceDue = invoice.balanceDue - invoice.salesTaxAmount;
  const additionalServices = parseNumberInput(
    additionalServiceAmounts.get(invoice.documentId),
  );
  const tip = parseNumberInput(invoiceTipAmounts.get(invoice.documentId));
  const salesTax =
    invoice.salesTaxAmount + invoice.salesTaxRate * additionalServices;

  return {
    balanceDue,
    additionalServices,
    tip,
    salesTax,
  };
}
